import { locationToStr, parseUrl, updateUrl } from "../helpers/url"
import paramWhitelistFilter from "../helpers/paramWhitelistFilter"

class StateMapping {
    constructor() {
        this.stateToRoute = this.stateToRoute.bind(this)
        this.routeToState = this.routeToState.bind(this)
        this.stateToRouteHandlers = []
        this.routeToStateHandlers = []
        this.enabled = true;
    }
    disable() {
        this.enabled = false;
    }
    enable() {
        this.enabled = true;
    }
    get available() {

        return parseUrl(window.location)?.type === "adListing"
    }
    addStateToRouteHandler(handler) {
        this.stateToRouteHandlers.push(handler)
    }
    removeStateToRouteHandler(handler) {
        const ix = this.stateToRouteHandlers.indexOf(handler);
        if (ix < 0) return;
        this.stateToRouteHandlers.splice(ix, 1)
    }
    addRouteToStateHandler(handler) {
        this.routeToStateHandlers.push(handler)
    }
    removeRouteToStateHandler(handler) {
        const ix = this.routeToStateHandlers.indexOf(handler);
        if (ix < 0) return;
        this.routeToStateHandlers.splice(ix, 1)
    }
    stateToRoute(uiState) {
        const result = {}
        if (!this.enabled || !this.available) return result;
        const state = uiState?.vehicles || {}
        //console.log("stateToRouteHandlers", this.stateToRouteHandlers.length)
        this.stateToRouteHandlers.forEach(handler => handler(state, result))
        //console.log("stateToRoute", state, result)
        return result
    }
    routeToState(routeState) {
        const state = {}
        if (!this.enabled || !this.available) return state;
        //console.log("routeToStateHandlers", this.routeToStateHandlers.length)
        this.routeToStateHandlers.forEach(handler => handler(routeState, state))
        const result = {
            vehicles: state
        }
        //console.log("routeToState", routeState, result)
        return result
    }
}
class Router {
    constructor() {
        this.callback = null;
        this.lastLocation = null;
        this.firstWrite = true;
        this.restart();
    }
    stop() {
        clearInterval(this.interval)
    }
    restart() {
        this.interval = setInterval(() => this.check(), 100)
    }
    check() {
        const currentLocation = locationToStr()
        if (currentLocation === this.lastLocation) return;
        this.lastLocation = currentLocation;
        this.updated();
    }
    onUpdate(callback) {
        this.callback = callback;
    }
    updated() {
        this.callback?.(this.read());
    }
    read() {
        return parseUrl();
    }
    write(route) {
        this.stop();
        const preserveUtm = this.firstWrite
        if (!preserveUtm) route = {
            ...route, searchParamFilter: paramWhitelistFilter,
        }
        this.lastLocation = updateUrl(route, { action: "push" })
        this.firstWrite = false
        this.restart();
    }
    createURL(state) { }
    dispose() { }

}

class Routing {
    constructor() {
        this.stateMapping = new StateMapping();
        this.router = new Router();
    }
    add({ stateToRoute, routeToState }) {
        this.stateMapping.addStateToRouteHandler(stateToRoute)
        this.stateMapping.addRouteToStateHandler(routeToState)
    }
    remove({ stateToRoute, routeToState }) {
        this.stateMapping.removeStateToRouteHandler(stateToRoute)
        this.stateMapping.removeRouteToStateHandler(routeToState)
    }
    disable() {
        this.stateMapping.disable()
    }
    enable() {
        this.stateMapping.enable()
    }
}

export const routing = new Routing();

