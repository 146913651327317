import React, { useContext, useLayoutEffect } from "react";
import algoliasearch from "algoliasearch/lite";
import { InstantSearch, useInstantSearch, useSearchBox } from "react-instantsearch-hooks-web";
import { createInsightsMiddleware } from 'instantsearch.js/es/middlewares';
import { aa } from "../hooks/useAnalytics";
import { routing } from "./algoliaRouting";
import { indices } from "../helpers/indices";
import getAdDetail from "../resources/adDetail";

const cacheAds = (ads) => {
    ads.forEach(i => getAdDetail.put(i.id, i));
}
function InsightsMiddleware() {
    const { use } = useInstantSearch();

    useLayoutEffect(() => {
        const middleware = createInsightsMiddleware({
            insightsClient: aa,
        });

        return use(middleware);
    }, [use]);

    return null;
}

const algoliaSearchClient = algoliasearch(
    process.env.REACT_ALGOLIA_APPLICATION_ID,
    process.env.REACT_ALGOLIA_SEARCH_API_KEY
);
const vehicleModelFormat = /^App\\Models\\Vehicle::\d+$/
export const searchClient = {
    ...algoliaSearchClient,
    async search(requests) {
        const result = await algoliaSearchClient.search(requests);
        const hits = result?.results?.flatMap(i => i?.hits);
        const cars = hits.filter(i => i.objectID?.match(vehicleModelFormat));
        cacheAds(cars);
        return result;
    },
}

// The waiting time after a keypress before a request is initiated
export const rateLimitMs = 250;

// The minimum number of characters to require before a request is made
//export const queryMinChars = 3;

export const queryValid = (query) => true;//(query?.length || 0) >= queryMinChars;

const queryHook = (() => {
    // rate limit queries
    let timer = null;
    return (query, hook) => {
        timer !== null && clearTimeout(timer);
        timer = setTimeout(() => {
            hook(query);
        }, rateLimitMs);
    };
})();


const searchFunction = (helper) => {
    if (!queryValid(helper.state.query)) return;
    //analyticsEvent("query", {
    //  query: helper.state.query,
    //  refinedFacets: helper.state.disjunctiveFacetsRefinements,
    //  resultsPage: helper.state.page,
    //});
    helper.search();
};

const SearchContext = React.createContext({})

export const InnerSearchProvider = ({ children }) => {
    const state = useSearchBox({
        queryHook,
    });
    return <SearchContext.Provider value={state}>{children}</SearchContext.Provider>
}

export const SearchProvider = ({ children }) => {
    return <InstantSearch
        searchClient={searchClient}
        indexName={indices.vehicles}
        searchFunction={searchFunction}
        routing={routing}
    >
        <InsightsMiddleware />
        <InnerSearchProvider>{children}</InnerSearchProvider>
    </InstantSearch>
}

/**
 * 
 * @returns {Search}
 */
export const useSearch = () => {
    return useContext(SearchContext)
}